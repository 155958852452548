const ERROR_404 = {
  // https://bentobox.sentry.io/issues/3337410252/events/latest/?project=6343811&referrer=latest-event
  type: 'Error',
  value: 'Request failed with status code 404'
};

const AXIOS_NETWORK_ERROR = {
  // https://bentobox.sentry.io/issues/3337426496/events/latest/?environment=production&project=6343811&referrer=latest-event
  type: 'AxiosError',
  value: 'Network Error'
};

const NETWORK_ERROR = {
  // https://bentobox.sentry.io/issues/3337426496/events/d3cff723bb204ab9b4b9619e1ed54451/
  type: 'Error',
  value: 'Network Error'
};

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  ERROR_404,
  AXIOS_NETWORK_ERROR,
  NETWORK_ERROR
];
